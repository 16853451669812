
function Menu_Clientes(){     
   
  
    return ( 
        
           <h2>Menu de Clientes Sidyna Lima</h2>  
    
        )
    }
    
    export default Menu_Clientes;