
import {Navigate} from "react-router-dom";

import "./principal.css";

import{use, useState} from 'react';

import MeuContexto from '../../context/context.jsx';

import { useEffect } from "react";

import Swal from 'sweetalert2'; //Alertas

import Cadastro_de_Clientes from "../cadastro_clientes/cadastro_de_clientes.jsx";


function Principal(){ 

    
    const {nome, Logado, setShow, id_usuario} = use(MeuContexto);   
      
       
        useEffect(() =>{            
            if (Logado == false){
                    Swal.fire({icon: 'error',  title: 'Atençao', text: 'Você Não Está Logado!'})                                   
                }                         
        },[]);


    const [linkar, setLinkar] = useState('');

    function func_Vendas(){ 
        
        if (id_usuario == 3){
            Swal.fire({icon: 'error',  title: 'Acesso', text: 'Não Permitido!'})
        } else {
            setLinkar('Vendas');   
        }              
    }


    function func_Linka_Pagamento(){ 
        if (id_usuario != 1){
            Swal.fire({icon: 'error',  title: 'Acesso', text: 'Não Permitido!'})
        } else {
            setLinkar('pagamentos');   
        }          
    }
   

    function clickCadastrarCliente(){

        if (id_usuario == 3){
            Swal.fire({icon: 'error',  title: 'Acesso', text: 'Não Permitido!'})
        } else {
            setShow(true);  
            <Cadastro_de_Clientes />   
        }

    }

    function func_sub_menu(){  
            setLinkar('sub_menu');               
    }

    function func_Comissoes(){

        if (id_usuario == 3){
            Swal.fire({icon: 'error',  title: 'Acesso', text: 'Não Permitido!'})
        } else {
            setLinkar('Comissoes'); 
        }

    }

    function func_Apurado(){

        if (id_usuario == 3){
            Swal.fire({icon: 'error',  title: 'Acesso', text: 'Não Permitido!'})
        } else {
            setLinkar('Apurado'); 
        }

    }


    function func_Balanco(){

        if (id_usuario != 1){
            Swal.fire({icon: 'error',  title: 'Acesso', text: 'Não Permitido!'})
        } else {
            setLinkar('Balanco'); 
        }

    }   
 
  
return ( 
    
         <div id="id_div_primaria">
        
            <div id="id_div_secundaria">

                    <Cadastro_de_Clientes />                       

                    {Logado === false ? <Navigate to='/' /> : null }          


                    <div id="operador_51">                    
                        <h2>{nome}</h2>                  
                    </div>                                                         

                    <div>
                                                <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Vendas} type="button">Caixa</button>
                                                {linkar === 'Vendas' ? <Navigate to='/vendas' /> : null}
                    </div>

                    <div>
                                                <button className="btn btn-outline-primary" id="largura_botoes"  onClick={clickCadastrarCliente} type="button">Cadastrar Clientes</button>
                                
                    </div>

                    <div>

                                                <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Comissoes} type="button">Comissões</button>
                                                {linkar === 'Comissoes' ? <Navigate to='/comissoes' /> : null}
         
                                                
                    </div>

                    <div>

                                                <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Apurado} type="button">Apurado</button>
                                                {linkar === 'Apurado' ? <Navigate to='/Lista_Fechamento' /> : null}                                        
                                             
                                                
                    </div>

                    <div>

                                                <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Balanco} type="button">Balanço</button>
                                                {linkar === 'Balanco' ? <Navigate to='/balanco' /> : null}                                                        
                                                
                    </div>

                    <div>

                                                <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Linka_Pagamento} type="button">Pagamentos</button>
                                                {linkar === 'pagamentos' ? <Navigate to='/pagamentos' /> : null}

                                                
                    </div>

                    <div>

                                                <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_sub_menu} type="button">Mais Menu</button>
                                                {linkar === 'sub_menu' ? <Navigate to='/sub_menu' /> : null}             
                                              
                    </div>

                    <div>

                            <h6>Sistema de Gestão Sidyna Lima</h6>

                    </div>            
            </div>
        </div> 

    )
}

export default Principal;




