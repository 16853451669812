

import React, { useState } from 'react';
import './login.css';

import {Link, Navigate}  from 'react-router-dom';
import AnimatedPage1 from "../../AnimatedPage1.jsx";
import api from "../../services/api.js";
import{use} from 'react';
import MeuContexto from '../../context/context.jsx';
import Swal from 'sweetalert2'; //Alertas


//<script src="https://cdn.tailwindcss.com"></script>

function Login_Vitrine() {

  const [Var_email, set_Var_email] = useState(""); 

  const [Var_senha, set_Var_senha] = useState("");

  const [tipo, set_Tipo] = useState("");  
  
  const [sucesso, setSucesso] = useState('');  
    
  const { setNome, setId_usuario, setEmail, setSenha, setLogado, setCod_empregado } = use(MeuContexto)
 

const func_Login = async () => { 

try{


      await api.post("/Login_de_Usuarios_Vitrine" , {
        email: Var_email,
        senha: Var_senha
      })

      .then((response) => {                                                   
          
          setNome(response.data.nome);          
          setId_usuario(response.data.id_usuario); 
          setEmail(response.data.email);
          setSenha(response.data.senha);
          set_Tipo(response.data.tipo);
          setCod_empregado(response.data.cod_empregado);
          setSucesso('S');
          setLogado(true);          
          
      })       

   } catch(error){
          setSucesso('N');
          Swal.fire({icon: 'error',  title: 'Erro', text: 'Usuário ou Senha Inválidos!'})          
      }
   }


  const [showKeyboard, setShowKeyboard] = useState(false); 

  const [posicao, setPosicao]= useState(1);

  const [valor, setValor] = useState('');

  const [valor2, setValor2] = useState('');

  const handleButtonClick = (numero) => {
    var var_CPF;
    var var_Senha;
    if (posicao===1) {
          
          var_CPF=((prev) => {
            const newCPF = valor + numero;
            if (newCPF.length <= 14) {
              return formatCPF(newCPF);
            }
            return prev;
          });
          set_Var_email(var_CPF);
          setValor(var_CPF)

         
         } else if (posicao===2) {

          var_Senha=(valor2 + numero)          
          set_Var_senha(var_Senha); 
          setValor2(var_Senha);        
          
         }
  };


  const handleDel = () => {
    if (posicao===1) {
      //setValor(valor.slice(0, -1));
 
     
      setValor((prev) => {
        const newCPF = valor.slice(0, -1);
        if (newCPF.length <= 14) {
          return formatCPF(newCPF);
        }
        return prev;
      });     
      

     } else if (posicao===2) {
      setValor2(valor2.slice(0, -1));
     }  

  };

  const formatCPF = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2');
  };

  const handleSair = () => {
         setShowKeyboard(false);
  };  

  const func_Abre_Teclado_1  = async () => {
    setShowKeyboard(true);
    setPosicao(1);        
  }

  const func_Abre_Teclado_2  = async () => {
    setShowKeyboard(true);
    setPosicao(2);        
  }


  

  return  ( 
    
    <div className="imputs-teclado"> 

      <div id="css_parte_1">       
         <img id="abelha" src="imagens/abelha.png" alt=""/>
      </div>
      <div>                       
             <input className="btn btn-outline-primary text-start" id='css_senha' type="text" value={valor} placeholder='CPF' onClick={func_Abre_Teclado_1} readOnly/>                            
      </div>
      <div>                              
             <input className="btn btn-outline-primary text-start" id='css_senha' type="password" value={valor2} placeholder='Senha' onClick={func_Abre_Teclado_2} readOnly/>                            
      </div>
      
      <div id='div_botao'>
          <button onClick={(e) =>func_Login(Var_email, Var_senha)} className="btn btn-outline-primary" id="css_botao" type="button">Acessar</button>
          {(sucesso === 'S' && tipo === 'A') ? <Navigate to='/principal' /> : 
          (sucesso === 'S' && tipo === 'P') ? <Navigate to='/Menu_Parceiros' /> :
          (sucesso === 'S' && tipo === 'C') ? <Navigate to='/Menu_Clientes' /> : null}
     
      </div>
      <div>
          {showKeyboard && (
            <div  className="teclado"> 
              <button className="btn" onClick={() => handleButtonClick('1')}>1</button>
              <button className="btn" onClick={() => handleButtonClick('2')}>2</button>
              <button className="btn" onClick={() => handleButtonClick('3')}>3</button>
              <button className="btn" onClick={() => handleButtonClick('4')}>4</button>
              <button className="btn" onClick={() => handleButtonClick('5')}>5</button>
              <button className="btn" onClick={() => handleButtonClick('6')}>6</button>
              <button className="btn" onClick={() => handleButtonClick('7')}>7</button>
              <button className="btn" onClick={() => handleButtonClick('8')}>8</button>
              <button className="btn" onClick={() => handleButtonClick('9')}>9</button>       
              <button className="del" onClick={handleDel}>Del</button>
              <button className="btn" onClick={() => handleButtonClick('0')}>0</button>
              <button className="sair" onClick={handleSair}>Sair</button>          
            </div>            
          )}
      </div>

    </div>
     
  );

 
}

export default Login_Vitrine;

