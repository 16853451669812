import api from "../../services/api.js"

import {Link} from "react-router-dom";

import "./comissao_individual.css";

import{useState} from 'react';

import DataTable from 'react-data-table-component';

import{use} from 'react';

import MeuContexto from '../../context/context.jsx';



function Comissao_Individual(){

    const { nome, cod_empregado } = use(MeuContexto)

    const [dt_pedido, setDtPedido] = useState("");

    const [dt_pedido_2, setDtPedido_2] = useState("");

    const [lista_2, setLista_2] = useState([]);

    //const [total_venda, setTotal_venda] = useState("0,00");

    var Data_1 = ''; 

    var Data_2 = '';
    
    const clickConsultar_2 = async () => { 
        
        Data_1 = dt_pedido;
        Data_2 = dt_pedido_2;    
        await func_Receber_lista_2(); 
        await func_Receber_Totais();       
    }    
    
const func_Receber_lista_2 = async () => {   
    try{
       
              //alert(e);
                    
              await api.post("/listar_comissoes_detalhada" , { 
                data_inicio: Data_1,               
                data_fim: Data_2,
                foder_e_bom: cod_empregado
                })

              .then((response) => { 
                  setLista_2(response.data);                             

              })            
             } catch(error){          
                console.log(error);
                }       
      } 

      const [total_comissao, setTotal_comissao] = useState("0,00");

      const func_Receber_Totais = async () => {   
              try{ 
                              
                        await api.post("/Traz_Total_comissao_individual" , { 
                          foder_e_bom: cod_empregado,
                          data_inicio: Data_1,               
                          data_fim: Data_2 
                          })
          
                        .then((response) => {
                             
                             //setTotal_venda(response.data.venda_total);
                             //setTotal_venda(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.venda_total));
      
                             //setTotal_comissao(response.data.comissao_total); 
                             setTotal_comissao(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.comissao_total));
                                      
          
                        })            
                       } catch(error){          
                          console.log(error);
                          }       
                } 


    
    const columns_2 = [

        {
          name: 'VENDA',
          selector: row => <>
              <p className="Tam_fonte">{row.data_comissao}</p>  
              <p className="Tam_fonte">{row.nome}</p> 
              <p className="Tam_fonte">{row.produto}</p>       
                         </>,
          maxwidth: "200px",   //largura maxima         
          compact: false,      
          left: true         
      },          
      
      {
          name: 'COM.',          
          selector: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.comissao),
          width: "100",          
          right: true,
          sortable: true            //cria ordenação 
      }
      ,       
      {
          name: '',          
          selector: row => row.id_contabil,
          width: "0px",  
          compact: true,
          right: true,
          sortable: true            //cria ordenação 
      }
    
    
    ]
       
   

return(

<div id='tela_casulo'>


    <div id="tela_inicial_V1">        
        
            <div id="tela_55_V1">
                <div id="css_500">   
                        <div id="css_501">
                             <input type="date" onChange={(e) => setDtPedido(e.target.value)} value={dt_pedido} className="form-control" id="InputEmail" aria-describedby="email" />                    
                        </div> 
                        <div>
                             {dt_pedido}    
                        </div>                   
                </div>

                <div id="css_500">   
                        <div id="css_501">
                            <input type="date" onChange={(e) => setDtPedido_2(e.target.value)} value={dt_pedido_2} className="form-control" id="InputEmail" aria-describedby="email" />                    
                        </div> 
                        <div>
                             {dt_pedido_2}   
                        </div>                   
                </div>                           


            </div>

            <div id="tela_55_V1">
                <div>
                       <button className="btn btn-outline-primary" id="largura_btn_50" onClick={clickConsultar_2} type="button">Consultar</button>
                </div>
                <div>
                      <Link to="/Menu_Parceiros" className="btn btn-outline-primary" id="largura_btn_50" >Sair</Link>
                </div>
            </div>

            <div id="tela_55_V1">
                    <div>
                         <h6>{nome}</h6> 
                    </div>                    
                    <div>
                         <h6>Comissão: {total_comissao}</h6>   
                    </div>                    
            </div>

            

            <div> 

                        <DataTable  columns={columns_2}                            
                            data={lista_2}    //dados de um DB
                            //pagination = {true}         //Põe a paginação 
                            //paginationComponentOptions={paginationOptions}    //Altera a linguagem da paginção                           
                            no noDataComponent={"Nenhum Registro Encontrado"}
                            fixedHeader={true}  //fixa o cabeçalho se for true
                            
                            selectableRowsHighlight  //escurece a linha quando o maus em cima
                            highlightOnHover         //junto com o de cima                            
                        /> 
            </div> 

    </div>
</div>
)

}

export default Comissao_Individual;

