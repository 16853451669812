

import FuncPassaVariavel from "../../components/funcoes/funcao1.jsx";
import FuncHome from "../home/home.jsx"
import {Link} from "react-router-dom"

import "./teste.css";

import AnimatedPage1 from "../../AnimatedPage1.jsx";

import Navbar from "../navbar/navbar.jsx";
import Footer from "../footer/footer.jsx";

import Img_1 from "../../assets/tesoura amarela4.jpg";

//traz a imagem a partir da pasta "public" 
//<img src="imagens/avatar.png"/>

import { useParams } from "react-router-dom";



function Teste(){

const { parametro_recebido_da_url } = useParams;



    let texto = "Enviando informação";

    const nome = "Rita Fiúra";

    return ( 
           
    <div>


        <Navbar />

        <AnimatedPage1> 
        
        <section  id="id_css">
            <div className="container">
                <div className="row">

                    <div className="col-lg-4" id="id_css_1">
                   
                        <h1 className="css_margin_2">Pronto para iniciar</h1>
                        <h3 className="css_margin_2">os teste com css</h3>

                        <div>
                            {parametro_recebido_da_url}
                        </div>

                        <Link to="/consultar" className="btn btn-primary btn-lg css_margin_1">Consultar</Link>                           
                        <Link to="/alterar" className="btn btn-outline-danger btn-lg css_margin_1">Alterar</Link>
                        <br></br> <br></br>
                        <Link to="/deletar" className="btn btn-warning">Deletar</Link>                        
                        <Link to="/incluir" className="btn btn-primary btn-lg">Incluir</Link>
                         <br></br> <br></br>            
                        <Link to="/alertas" className="btn btn-success">Alertas</Link>                                
                        <Link to="/video" className="btn btn-outline-danger">Exibir Vídeo</Link> 
                        <br></br> <br></br>
                        <Link className="btn btn-outline-light" aria-current="page" to="/teste2">Novo Teste</Link>
                        <br></br><br></br>

                        <FuncPassaVariavel Var_Nome={nome} Var_Idade="28 anos"/> 
                        <FuncPassaVariavel Var_Nome="João Silva" Var_Idade="31 anos"/> 
                    </div>                    

                    <div className="col-lg-4" id="id_css_2">


                       
                             <FuncHome />
                                        
                        
                    </div>

                    <div className="col-lg-4" id="id_css_3">
                           
                       <img src={Img_1} alt="Fundo Indicator" />                                                  

                    </div>                  

                </div> 
            </div> 
        </section>        

        </AnimatedPage1>

        <Footer />         
         
    </div> 

    )
}

export default Teste;
