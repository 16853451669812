import {Navigate} from "react-router-dom";

import "./menu_parceiros.css";

import{use, useState} from 'react';

import MeuContexto from '../../context/context.jsx';

import { useEffect } from "react";

import Swal from 'sweetalert2'; //Alertas

function Menu_Parceiros(){  
    
    const {nome, Logado, setShow, id_usuario} = use(MeuContexto);
    
    const [linkar, setLinkar] = useState('');
    
    
      
       {/*
        useEffect(() =>{            
            if (Logado == false){
                    Swal.fire({icon: 'error',  title: 'Atençao', text: 'Você Não Está Logado!'})                                   
                }                         
        },[]);
        */}


        function func_Comissao_Individual(){ 
                setLinkar('Comissao');    
        }  
        
        //{Logado === false ? <Navigate to='/' /> : null }
   
  
return ( 
    <div id="id_div_primaria">
        
    <div id="id_div_secundaria">
       <h5>Menu Profissionais Parceiros</h5>           
           
           

                    <div id="operador_51">                    
                        <h2>{nome}</h2>                  
                    </div>                                                         

                    <div>
                           
                           <button className="btn btn-outline-primary" id="largura_botoes" onClick={func_Comissao_Individual} type="button">Comissão</button>
                           {linkar === 'Comissao' ? <Navigate to='/Comissao_Individual' /> : null} 

                    </div>
                    <div>
                           <button className="btn btn-outline-primary" id="largura_botoes" type="button">Prospecção</button>                                
                    </div>
                    <div>
                            <h6>Sistema de Gestão Sidyna Lima</h6>
                    </div> 


    </div>
    </div>

    )
}

export default Menu_Parceiros;

